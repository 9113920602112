import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import AdminLayout from '@/layouts/AdminLayout.vue'
import store from '../store'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('@/views/HomePage'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/image-zoom',
		name: 'ImageZoom',
		component: () => import('@/views/user/image-zoom'),
		requiresAdminAuth: false
	},
	{
		path: '/privacy-policy',
		name: 'PrivacyPolicy',
		component: () => import('@/views/user/privacy-policy'),
		requiresAdminAuth: false,
	},
	{
		path: '/license',
		name: 'LicensePage',
		component: () => import('@/views/user/license'),
		requiresAdminAuth: false,
	},
	{
		path: '/android-app-download',
		name: 'DownloadApk',
		component: () => import('@/views/user/download-apk'),
		requiresAdminAuth: false,
	},
	{
		path: '/terms',
		name: 'TermsCondition',
		component: () => import('@/views/user/terms-condition'),
		requiresAdminAuth: false,
	},
	{
		path: '/faq',
		name: 'FAQPage',
		component: () => import('@/views/user/faq'),
		requiresAdminAuth: false,
	},
	{
		path: '/delete-account',
		name: 'VerifyDeleteAccount',
		component: () => import('@/views/user/VerifyAccountPage'),
		requiresAdminAuth: false,
	},
	{
		path: '/snapchat-login',
		name: 'SnapchatLogin',
		component: () => import('@/views/user/snapchat-login'),
		requiresAdminAuth: false,
	},
	{
		path: '/instagram-login',
		name: 'InstagramLogin',
		component: () => import('@/views/user/instagram-login'),
		requiresAdminAuth: false,
	},
	{
		path: '/instagram-logout',
		name: 'InstagramLogout',
		component: () => import('@/views/user/instagram-logout'),
		requiresAdminAuth: false,
	},
	{
		path: '/admin',
		redirect: '/admin/login',
		name: 'Admin',
		meta: { layout: AdminLayout },
		children: [
			{
				path: 'login',
				name: 'Login',
				component: () => import('@/views/admin/auth/Login'),
				requiresAdminAuth: false,
			},
			{
				path: 'password/reset',
				name: 'ForgotPassword',
				component: () => import('@/views/admin/auth/ForgotPassword'),
				requiresAdminAuth: false,
			},
			{
				path: 'password/reset/:token',
				name: 'ResetPassword',
				component: () => import('@/views/admin/auth/ResetPassword'),
				requiresAdminAuth: false,
			},
			{
				path: 'dashboard',
				name: 'Dashboard',
				component: () => import('@/views/admin/AdminDashboard'),
				meta: {
					title: 'Dashboard - Mixr',
					metaTags: [{name: 'description',content: 'The dashboard page of our Mixr.'},{property: 'og:description',content: 'The dashboard page of our Mixr.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: '403',
				name: '403',
				component: () => import('@/views/admin/AdminDashboard'),
				meta: {
					title: 'Dashboard - Mixr',
					metaTags: [{name: 'description',content: 'The dashboard page of our Mixr.'},{property: 'og:description',content: 'The dashboard page of our Mixr.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'profile-update',
				name: 'Profile Update',
				component: () => import('@/views/admin/ProfileUpdate'),
				meta: {
					title: 'Dashboard - profile-update',
					metaTags: [{name: 'description',content: 'The login user profile update page of our Mixr.'},{property: 'og:description',content: 'The dashboard page of our Mixr.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'change-password',
				name: 'Change Password',
				component: () => import('@/views/admin/ChangePassword'),
				meta: {
					title: 'Dashboard - Mixr',
					metaTags: [{name: 'description',content: 'The dashboard page of our Mixr.'},{property: 'og:description',content: 'The dashboard page of our Mixr.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: "user",
				name: "User",
				redirect: '/admin/user/list',
				children: [
					{
						path: "list",
						name: "User List",
						component: () => import('@/views/admin/user/List'),
						meta: {
							title: 'User Management - Mixr',
							metaTags: [{name: 'description',content: 'The user Management page of our Mixr.'},{property: 'og:description',content: 'The user Management page of our Mixr.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/view",
						name: "User View",
						component: () => import('@/views/admin/user/View'),
						meta: {
							title: 'User Management - Mixr',
							metaTags: [{name: 'description',content: 'The user Management page of our Mixr.'},{property: 'og:description',content: 'The user Management page of our Mixr.'}],
							requiresAdminAuth: true,
						}
					},
				]
			},
			{
				path: "report-user",
				name: "Report User List",
				redirect: '/admin/report-user/list',
				children: [
					{
						path: "list",
						name: "Report User List",
						component: () => import('@/views/admin/reportUser/List'),
						meta: {
							title: 'Report User Management - Mixr',
							metaTags: [{name: 'description',content: 'The Report User Management page of our Mixr.'},{property: 'og:description',content: 'The Report User Management page of our Mixr.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/view",
						name: "Report User View",
						component: () => import('@/views/admin/reportUser/View'),
						meta: {
							title: 'Report User Management - Mixr',
							metaTags: [{name: 'description',content: 'The Report User Management page of our Mixr.'},{property: 'og:description',content: 'The Report User Management page of our Mixr.'}],
							requiresAdminAuth: true,
						}
					},
				]
			},
			{
				path: "gender",
				name: "Gender",
				redirect: '/admin/gender/list',
				children: [
					{
						path: "list",
						name: "Gender List",
						component: () => import('@/views/admin/gender/List'),
						meta: {
							title: 'Gender Management - Mixr',
							metaTags: [{name: 'description',content: 'The Gender Management page of our Mixr.'},{property: 'og:description',content: 'The Gender Management page of our Mixr.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: "create",
						name: "Gender Create",
						component: () => import('@/views/admin/gender/Create'),
						meta: {
							title: 'Gender Management - Mixr',
							metaTags: [{name: 'description',content: 'The Gender Management page of our Mixr.'},{property: 'og:description',content: 'The Gender Management page of our Mixr.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/edit",
						name: "Gender Edit",
						component: () => import('@/views/admin/gender/Edit'),
						meta: {
							title: 'Gender Management - Mixr',
							metaTags: [{name: 'description',content: 'The Gender Management page of our Mixr.'},{property: 'og:description',content: 'The Gender Management page of our Mixr.'}],
							requiresAdminAuth: true,
						}
					},
				],
			},
			{
				path: "relationship-goal",
				name: "Relationship Goal",
				redirect: '/admin/relationship-goal/list',
				children: [
					{
						path: "list",
						name: "Relationship Goal List",
						component: () => import('@/views/admin/relationshipGoal/List'),
						meta: {
							title: 'Relationship Goal Management - Mixr',
							metaTags: [{name: 'description',content: 'The Relationship Goal Management page of our Mixr.'},{property: 'og:description',content: 'The Relationship Goal Management page of our Mixr.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: "create",
						name: "Relationship Goal Create",
						component: () => import('@/views/admin/relationshipGoal/Create'),
						meta: {
							title: 'Relationship Goal Management - Mixr',
							metaTags: [{name: 'description',content: 'The Relationship Goal Management page of our Mixr.'},{property: 'og:description',content: 'The Relationship Goal Management page of our Mixr.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/edit",
						name: "Relationship Goal Edit",
						component: () => import('@/views/admin/relationshipGoal/Edit'),
						meta: {
							title: 'Relationship Goal Management - Mixr',
							metaTags: [{name: 'description',content: 'The Relationship Goal Management page of our Mixr.'},{property: 'og:description',content: 'The Relationship Goal Management page of our Mixr.'}],
							requiresAdminAuth: true,
						}
					},
				],
			},
			{
				path: "report-type",
				name: "Report Type",
				redirect: '/admin/report-type/list',
				children: [
					{
						path: "list",
						name: "Report Type List",
						component: () => import('@/views/admin/reportType/List'),
						meta: {
							title: 'Report Type Management - Mixr',
							metaTags: [{name: 'description',content: 'The Report Type Management page of our Mixr.'},{property: 'og:description',content: 'The Report Type Management page of our Mixr.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: "create",
						name: "Report Type Create",
						component: () => import('@/views/admin/reportType/Create'),
						meta: {
							title: 'Report Type Management - Mixr',
							metaTags: [{name: 'description',content: 'The Report Type Management page of our Mixr.'},{property: 'og:description',content: 'The Report Type Management page of our Mixr.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/edit",
						name: "Report Type Edit",
						component: () => import('@/views/admin/reportType/Edit'),
						meta: {
							title: 'Report Type Management - Mixr',
							metaTags: [{name: 'description',content: 'The Report Type Management page of our Mixr.'},{property: 'og:description',content: 'The Report Type Management page of our Mixr.'}],
							requiresAdminAuth: true,
						}
					},
				],
			},
			{
				path: "ethnicity",
				name: "Ethnicity",
				redirect: '/admin/ethnicity/list',
				children: [
					{
						path: "list",
						name: "Ethnicity List",
						component: () => import('@/views/admin/ethnicity/List'),
						meta: {
							title: 'Ethnicity Management - Mixr',
							metaTags: [{name: 'description',content: 'The Ethnicity Management page of our Mixr.'},{property: 'og:description',content: 'The Ethnicity Management page of our Mixr.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: "create",
						name: "Ethnicity Create",
						component: () => import('@/views/admin/ethnicity/Create'),
						meta: {
							title: 'Ethnicity Management - Mixr',
							metaTags: [{name: 'description',content: 'The Ethnicity Management page of our Mixr.'},{property: 'og:description',content: 'The Ethnicity Management page of our Mixr.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/edit",
						name: "Ethnicity Edit",
						component: () => import('@/views/admin/ethnicity/Edit'),
						meta: {
							title: 'Ethnicity Management - Mixr',
							metaTags: [{name: 'description',content: 'The Ethnicity Management page of our Mixr.'},{property: 'og:description',content: 'The Ethnicity Management page of our Mixr.'}],
							requiresAdminAuth: true,
						}
					},
				],
			},
		],
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		// always scroll to top
		return { top: 0 }
	},
  })

router.beforeEach((to, from, next) => {
	// Set the title
	document.title = to.meta.title || 'MixR - The Better App' // Default title if not set

	// Set the meta tags
	const metaTags = to.meta.metaTags
	if (metaTags) {
		metaTags.forEach(tag => {
			const { name, content } = tag
			const metaTag = document.createElement('meta')
			metaTag.setAttribute(name, content)
			document.head.appendChild(metaTag)
		});
	}

	// Determine the layout based on the meta property of the route
	const layout = to.meta.layout || 'DefaultLayout'
	// Update the layout component dynamically based on the layout value
	if (layout === 'DefaultLayout' || layout === 'AdminLayout') {
        // Check if the Vue instance is available
        if (router.app) {
            router.app.$options.components.Layout = layout === 'AdminLayout' ? AdminLayout : DefaultLayout;
        } else {
            console.error('Vue instance not available.');
        }
    }

	const isAdminLoggedIn = store.getters.isLoggedIn; // Implement the isLoggedIn getter in your store

	if (to.matched.some(route => route.meta.requiresAdminAuth) && isAdminLoggedIn === false) {
		// If the route requires authentication and the user is not logged in
		next('/admin');
	} else {
		if(to.path == '/admin/login' && isAdminLoggedIn === true){
			next('/admin/dashboard');
		}
	}

	if(isAdminLoggedIn === true && to.name != "Dashboard"){
		next();
	}

	next();
})

export default router
